import { useEffect } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { DempyouType } from 'pages/prints/dempyou';
import { useForm } from 'antd/es/form/Form';
import { OmsRecycleOrderDetail, OmsRecycleOrderProductInfo } from 'types/oms';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { useCityList } from 'apis/home';
import i18n from 'i18n';

interface Props {
  open: boolean;
  onCancel: () => void;
  dataSource: OmsRecycleOrderDetail;
  productInfo: OmsRecycleOrderProductInfo;
}

const DempyouModal = ({ open, onCancel, dataSource, productInfo }: Props) => {
  const [dempyouForm] = useForm();
  const { staffSelectOptions, countryOptions } =
    useAppSelector(selectGlobalInfo);

  const cityList = useCityList(
    dataSource.omsRecycleOrderLogistics?.country || ''
  );

  useEffect(() => {
    const { name, phone, postCode, country, city, detailAddress } =
      dataSource.omsRecycleOrderLogistics || {};
    const { code, settlementType, createBy, createTime, type } =
      dataSource.omsRecycleOrder || {};
    const { finalSalePrice, finalRecyclePrice, guestRemarks } =
      dataSource.omsRecycleOrderItem || {};

    const countryStr = countryOptions.find((d) => d.value === country);
    const countryIsJP = countryStr?.value === 'JPN';
    const countryLabel = countryIsJP ? '' : countryStr?.label;
    const cityStr = cityList.find((d) => d.code === city);
    const nameStr = countryIsJP ? `${name || '-'}  様` : `${name || '-'}`;
    const userInfo = `${nameStr}\n+ ${phone || '-'}\n${countryLabel}${
      cityStr?.name || ''
    }${detailAddress} ${postCode || ''}`;

    const { category, info } = productInfo;
    let productName = ``;
    if (info && info.length) {
      productName += `${category} ${info[2].value} ${info[0].value} ${info[4].value} ${info[3].value} ${info[5].value}`;
    }
    const productPrice =
      (type === 1 ? finalSalePrice : finalRecyclePrice) || '';

    dempyouForm.setFieldsValue({
      code,
      date: dayjs(createTime).format('YYYY/MM/DD'),
      staff: createBy,
      payType: ['', '現金', '振込'][settlementType || 1],
      userInfo,
      productName,
      guestRemarks,
      productPrice,
    });
  }, [dataSource, countryOptions, cityList, dempyouForm, productInfo]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title="伝票印刷"
      onOk={async () => {
        const { staff, productName, guestRemarks, productPrice, ...rest } =
          dempyouForm.getFieldsValue();
        const type = dataSource.omsRecycleOrder?.type;
        const temp = {
          staff: staffSelectOptions.find((d) => d.value === staff)?.label,
          ...rest,
          totalPrice: productPrice,
          prints: true,
          productList: [
            {
              productSn: dataSource.omsRecycleOrderProduct?.productSn,
              productName,
              guestRemarks,
              productPrice,
              brandName: dataSource.omsRecycleOrderProduct?.brandName,
              productId: dataSource.omsRecycleOrderItem?.productId,
            },
          ],
          memberId: dataSource.omsRecycleOrder?.memberId,
        };
        // 判断类型
        const [t1, t2] =
          type === 1
            ? [DempyouType.sell_customer, DempyouType.sell_store]
            : [DempyouType.recycle_customer, DempyouType.recycle_store];
        // 一次性打印两张
        const encode = encodeURIComponent(
          JSON.stringify([
            { ...temp, dempyouType: t1 },
            { ...temp, dempyouType: t2 },
          ])
        );
        window.open(`/prints/dempyou?body=${encode}`);
      }}
      width={1280}
    >
      <Form form={dempyouForm} labelCol={{ span: 8 }}>
        <div className="grid grid-cols-2 gap-2">
          <Form.Item label="伝票番号" name="code">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="受付日" name="date">
            <Input></Input>
          </Form.Item>
          <Form.Item label="担当者" name="staff">
            <Select
              options={staffSelectOptions.map((option) => {
                return {
                  value: option.value,
                  label: option.label,
                };
              })}
            ></Select>
          </Form.Item>
          <Form.Item label="ご清算区分" name="payType">
            <Select
              options={[
                {
                  value: '振込',
                  label: '振込',
                },
                {
                  value: '現金',
                  label: '現金',
                },
              ]}
            ></Select>
          </Form.Item>
          <Form.Item label="お客様情報" name="userInfo">
            <Input.TextArea rows={5}></Input.TextArea>
          </Form.Item>
          <Form.Item label="商品名" name="productName">
            <Input.TextArea rows={5}></Input.TextArea>
          </Form.Item>
          <Form.Item label={i18n.t('guest_remarks')} name="guestRemarks">
            <Input.TextArea
              rows={5}
              placeholder={i18n.t('show_to_the_guest') || ''}
            ></Input.TextArea>
          </Form.Item>
          <Form.Item label="金額(税込)" name="productPrice">
            <Input></Input>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default DempyouModal;
